import { FC, useCallback, useEffect, useState } from 'react';

import { Button } from 'antd';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';

import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useBenguide from 'hooks/benguide';

import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import PageHeader from 'components/PageHeader/PageHeader';
import NoPlansView from 'components/NoPlansView/NoMDVPlansView';
import AddOrRemovePlans from 'modules/benefitsWellness/components/AddOrRemovePlans/AddOrRemovePlans';
import WellbeingCard from 'modules/benefitsWellness/components/WellbeingCard/WellbeingCard';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import WellbeingPlansChannel from 'modules/clients/UPClient/channels/WellbeingPlansChannel';
import PlanReference from 'model/PlanReference';
import AddPlanApiRequestPayload from 'model/AddPlanApiRequestPayload';
import { ReactComponent as WellbeingIcon } from 'images/pageHeaderIcons/wellbeing.svg';

import {
  fetchWellbeingPlanList,
  addPlans,
  getDefaultText,
} from 'modules/benefitsWellness/slices/wellbeingSlice';

import { BENEFIT_CATEGORY } from 'constants/commonConstants';
import { WELLBEING_PLAN_CHANNEL } from 'modules/clients/UPClient/UPClientConst';
import BenefitGuideRefreshChannel from 'modules/clients/UPClient/channels/BenefitGuideRefreshChannel';
import BenefitGuideNotificationsChannel from 'modules/clients/UPClient/channels/BenefitGuideNotificationsChannel';
import { NotificationContextProvider } from 'context/NotificationContext';
import { swapElements } from 'modules/benefitsWellness/util';
import styles from './wellbeing.module.less';

const Wellbeing: FC = () => {
  const dispatch = useAppDispatch();
  const { wellbeing } = useAppSelector((state: any) => state);
  const [isSaveDisable, setSaveDisable] = useState<boolean>(false);
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);
  const [sortedWellBeingPlans, setSortedPlans] = useState<any[]>([]);
  const { planList, wellbeingSection = [] } = wellbeing;
  const { plans: addedPlans = [] } = wellbeingSection;
  const benguide = useBenguide();
  const { masterId, planYearId, employerId, benefitClasses } = benguide;
  const addedPlansLoading = useAppSelector(
    (state) => state.wellbeing.wellbeingSection.inProgress
  );

  const isEditMode = benguide.isEditMode;

  useEffect(() => {
    dispatch(fetchWellbeingPlanList(employerId, planYearId, benefitClasses));
  }, [dispatch, employerId, planYearId, benefitClasses]);

  useEffect(() => {
    if (addedPlans) {
      const uniquePlansList = uniqBy(addedPlans, 'benefitKind');
      if (uniquePlansList) {
        uniquePlansList.forEach((element: any) => {
          if (element.id && element.benefitKind) {
            dispatch(getDefaultText(masterId, element.id));
          }
        });
        setSortedPlans(addedPlans);
      }
    }
  }, [dispatch, addedPlans, masterId]);

  const moveTop = (index: number) => {
    if (addedPlansLoading) return;
    if (index > 0) {
      const newPlans = swapElements(
        sortedWellBeingPlans,
        index,
        index - 1
      ) as any[];
      setSortedPlans(newPlans);
      updatePlans(newPlans, false);
    }
  };

  const moveBottom = (index: number) => {
    if (addedPlansLoading) return;
    if (index < sortedWellBeingPlans.length - 1) {
      const newPlans = swapElements(
        sortedWellBeingPlans,
        index,
        index + 1
      ) as any[];
      setSortedPlans(newPlans);
      updatePlans(newPlans, false);
    }
  };

  const updatePlans = (plans: any[], orderByPlanNames: boolean) => {
    const planReferences = plans.map((plan: PlanReference) => {
      return {
        ...plan,
        planId: plan.id,
        planName: plan.name,
        carrier: {
          id: get(plan, 'carrier.id', null),
          name: get(plan, 'carrier.name', null),
        },
        benefitCarrier: {
          id: get(plan, 'benefitCarrier.id', null),
          name: get(plan, 'benefitCarrier.name', null),
        },
      };
    });
    const addPlanRequest = {
      plans: planReferences,
      sectionName: BENEFIT_CATEGORY.WELLBEING.value,
      orderByPlanNames: orderByPlanNames,
    } as AddPlanApiRequestPayload;
    dispatch(addPlans(masterId, addPlanRequest));
  };

  const reloadAllWellbeingPlans = useCallback(() => {
    dispatch(fetchWellbeingPlanList(employerId, planYearId, benefitClasses));
  }, [employerId, planYearId, dispatch, benefitClasses]);

  const showAlert = () => {
    setIsAlertVisible(true);
  };

  const getAlert = () => (
    <AlertMessage
      className={styles.alert}
      type="success"
      message={'Successfully created new plan.'}
      closeAlert={() => setIsAlertVisible(false)}
      wrapperClassName={styles.planAlertWrapper}
    />
  );

  return (
    <NotificationContextProvider isDisableSave={isSaveDisable}>
      {isAlertVisible && getAlert()}
      <div className={styles.wellbeingWrapper}>
        <WellbeingPlansChannel />
        <BenefitGuideRefreshChannel
          benefitKind={BENEFIT_CATEGORY.WELLBEING.value}
        />
        <BenefitGuideNotificationsChannel
          setDisableSave={() => setSaveDisable(true)}
        />
        <PageHeader
          pageHeaderTitle="Wellbeing"
          pageHeaderIcon={<WellbeingIcon />}
          customIconStyle={styles.wellbeingIcon}
        />
        {isEditMode && !isEmpty(addedPlans) && (
          <div className={styles.addOrRemovePlansWrapper}>
            <EditableHotspot
              alwaysVisible={true}
              customModal={(visible: boolean, onClose: Function) => (
                <AddOrRemovePlans
                  visible={visible}
                  onClose={onClose}
                  title="Wellbeing"
                  onSave={updatePlans}
                  addedPlanList={addedPlans}
                  allPlans={planList.data}
                  reloadPlans={reloadAllWellbeingPlans}
                  channel={WELLBEING_PLAN_CHANNEL}
                  showAlert={showAlert}
                  isLoading={planList.inProgress}
                />
              )}
            >
              <Button>+ Add or Remove Plans</Button>
            </EditableHotspot>
          </div>
        )}

        {sortedWellBeingPlans?.length > 0 ? (
          <div className={styles.wellbeingContainer}>
            {sortedWellBeingPlans?.map((plan: any, index: any) => (
              <WellbeingCard
                key={index}
                plan={plan}
                moveTop={() => moveTop(index)}
                moveBottom={() => moveBottom(index)}
                hideTop={index === 0}
                hideBottom={index === sortedWellBeingPlans?.length - 1}
              />
            ))}
          </div>
        ) : (
          isEditMode && (
            <AddOrRemovePlans
              visible
              withoutModal
              title="Wellbeing"
              onSave={updatePlans}
              addedPlanList={addedPlans}
              allPlans={planList.data}
              reloadPlans={reloadAllWellbeingPlans}
              channel={WELLBEING_PLAN_CHANNEL}
              showAlert={showAlert}
              isLoading={planList.inProgress}
            />
          )
        )}
        {isEmpty(addedPlans) && !isEditMode && (
          <NoPlansView className={styles.noPlanView} />
        )}
      </div>
    </NotificationContextProvider>
  );
};

export default Wellbeing;
