import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import find from 'lodash/find';

import MDVPlan from 'model/MDVPlan';
import MDVService from 'model/MDVService';
import MDVMetaService from 'model/MDVMetaService';
import {
  COST_TYPE,
  FREQUENCY_LABEL,
  IN_NETWORK,
  NOT_COVERED_FORMATTED,
  NO_INFO,
  SERVICE_APPLICABLE,
  FREQUENCIES,
  AGE_BAND,
} from 'modules/benefitsWellness/constants/BenefitConstants';
import {
  NOT_APPLICABLE,
  NOT_APPLICABLE_FORMATTED,
} from 'utils/planRecommenderUtils/planRecommenderUtils';
import FrequencyLabels from 'modules/benefitsWellness/enums/FrequencyLabels';
import PolicyEnable from 'modules/benefitsWellness/enums/PolicyEnable';
import MDVMetaGroup from 'model/MDVMetaGroup';
import { baseApi } from 'utils/apiUtil';
import Plan from 'model/Plan';
import PlanReference from 'model/PlanReference';
import ContributionFrequency from 'model/ContributionFrequency';
import { trackEvents } from 'utils/initGA4';
import { addHttp } from 'utils/httpUtil';
import { ALL_DOCUMENT_TYPE } from 'modules/resources/constants';
import {MDVPlanSuperscript, Parameter} from 'model/LableTypes';

const defaultValue = '-';

export const changePlanOrder = (
  oldIndex: number,
  newIndex: number,
  plans: MDVPlan[],
  onUpdatePlanList: Function
) => {
  const clonedPlan = cloneDeep(plans) as any[];
  if (newIndex >= clonedPlan.length) {
    let k = newIndex - clonedPlan.length + 1;
    while (k--) {
      clonedPlan.push(undefined);
    }
  }
  clonedPlan.splice(newIndex, 0, clonedPlan.splice(oldIndex, 1)[0]);

  onUpdatePlanList(clonedPlan, false);
};

export const checkIfPlanDocumentsAvailable = (
  plans: MDVPlan[],
  documentType: string
) => {
  const availableDocuments = [] as string[];
  plans.forEach((plan: MDVPlan) => {
    if (plan.documents.hasOwnProperty(documentType)) {
      availableDocuments.push(plan.documents[documentType]);
    }
  });
  return !isEmpty(availableDocuments);
};

export const getConfiguredBenefitCodeList = (titleGroups: MDVMetaGroup[]) => {
  const benefitCodes = [] as number[];
  titleGroups.forEach((group) => {
    group.elements?.forEach((groupItem: any) => {
      if ('serviceBenefitCode' in groupItem) {
        benefitCodes.push(groupItem.serviceBenefitCode);
      }
    });
  });
  return benefitCodes;
};

export const getAdditionalServices = (
  planList: MDVPlan[],
  definedBenefitCodes: number[]
) => {
  const additionalServiceList = [] as MDVMetaService[];
  const alreadyIncludedAdditionalServices = [] as number[];
  planList.forEach((plan) => {
    if (plan.customServices) {
      plan.customServices.forEach((service) => {
        if (service.benefitCode && service.benefitCode.code) {
          if (
            !definedBenefitCodes.includes(service.benefitCode.code) &&
            !alreadyIncludedAdditionalServices.includes(
              service.benefitCode.code
            )
          ) {
            additionalServiceList.push({
              fieldName: 'additionalService',
              fieldValue: service.benefitCode.shortName,
              serviceBenefitCode: service.benefitCode.code,
              fieldDescription: service.benefitCode.shortName,
              fieldFormatter: 'customServiceFormatter',
            });
            alreadyIncludedAdditionalServices.push(service.benefitCode.code);
          }
        }
      });
    }
  });
  return additionalServiceList;
};

const appendSuperScripts = (data: MDVService, formattedValue: string) => {
  if (data && data.costSharingPolicy) {
    if (
      (data.costSharingPolicy.toString().toLowerCase() === COST_TYPE.COPAY &&
        (data.copayPriorToDeductible === PolicyEnable.NO ||
          data.copayPriorToDeductible === undefined)) ||
      (data.costSharingPolicy.toString().toLowerCase() ===
        COST_TYPE.COINSURANCE &&
        (data.copayPriorToDeductible === PolicyEnable.NO ||
          data.copayPriorToDeductible === undefined)) ||
      (data.costSharingPolicy.toString().toLowerCase() === COST_TYPE.OTHER &&
        !isEmpty(data.info) &&
        (data.copayPriorToDeductible === PolicyEnable.NO ||
          data.copayPriorToDeductible === undefined))
    ) {
      return `${formattedValue} SUP-1`;
    }
  }
  return formattedValue;
};

const showFrequency = (frequency: FrequencyLabels) => {
  return FREQUENCY_LABEL[frequency];
};

const getFormattedCopayValue = (data: MDVService, includeFrequency = true) =>
  COST_TYPE.COPAY in data
    ? `${currencyFormatter(parseFloat(data.copay || ''))} ${
        includeFrequency && data.copayFrequency
          ? showFrequency(data.copayFrequency)
          : ''
      }`
    : defaultValue;

const getFormattedCoinsuranceValue = (data: MDVService) =>
  COST_TYPE.COINSURANCE in data ? `${data.coinsurance}%` : defaultValue;

const regularFormatter = (data: MDVService, includeFrequency = true) => {
  if (data && data.benefitCovered) {
    if (data.costSharingPolicy?.toString() === NOT_APPLICABLE)
      return NOT_APPLICABLE_FORMATTED;
    if (data.benefitCovered.toString().toLowerCase() === 'no')
      return NOT_COVERED_FORMATTED;
    if (data.benefitCovered.toString().toLowerCase() === 'no_info')
      return NO_INFO;
  }
  if (data && data.costSharingPolicy) {
    if (data.costSharingPolicy.toString().toLowerCase() === COST_TYPE.COPAY)
      return appendSuperScripts(
        data,
        getFormattedCopayValue(data, includeFrequency)
      );
    if (
      data.costSharingPolicy.toString().toLowerCase() === COST_TYPE.COINSURANCE
    )
      return appendSuperScripts(data, getFormattedCoinsuranceValue(data));
    if (data.costSharingPolicy.toString().toLowerCase() === COST_TYPE.OTHER)
      return appendSuperScripts(data, data.info || '') || defaultValue;
  }
  if (data && !isEmpty(data.info)) {
    return appendSuperScripts(data, data.info || '') || defaultValue;
  }
  return defaultValue;
};

export const currencyFormatter = (num: number) => {
  let value =
    num && !isNaN(num)
      ? '$' +
        Number(num)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      : '$0';

  const decimalsOfNum = value.split('.');
  if (decimalsOfNum[1] === '00') value = decimalsOfNum[0];

  return value;
};

export const coverageFormatter = (
  value: number | undefined,
  applicableText: string
) => {
  if (applicableText !== undefined) {
    if (applicableText === SERVICE_APPLICABLE.NOT_APPLICABLE) {
      return NOT_APPLICABLE_FORMATTED;
    } else if (
      applicableText === SERVICE_APPLICABLE.COVERED &&
      value !== undefined
    ) {
      return String(value);
    } else if (applicableText === SERVICE_APPLICABLE.NOT_COVERED) {
      return NOT_COVERED_FORMATTED;
    }
    return defaultValue;
  }
  return defaultValue;
};

export const fieldFormatters = (data: MDVService, formatter: string) => {
  if (formatter === 'customServiceFormatter') return regularFormatter(data);
};

export const appendRxSuperScripts = (rxObject: any, currentNetwork: string) => {
  let response = '';
  if (rxObject.inNetwork && currentNetwork === 'inNetwork') {
    const rxMap = new Map(Object.entries(rxObject.inNetwork));
    rxMap.forEach((value: any) => {
      if (
        value.copayPriorToDeductible === 'NO' ||
        (value.costSharingPolicy !== undefined &&
          value.copayPriorToDeductible === undefined)
      ) {
        response = 'SUP-1';
      }
    });
  } else if (rxObject.outOfNetwork && currentNetwork === 'outOfNetwork') {
    const rxMap = new Map(Object.entries(rxObject.outOfNetwork));
    rxMap.forEach((value: any) => {
      if (
        value.copayPriorToDeductible === 'NO' ||
        (value.costSharingPolicy !== undefined &&
          value.copayPriorToDeductible === undefined)
      ) {
        response = 'SUP-1';
      }
    });
  }

  return response;
};

export const hasSuperScripts = (plans: MDVPlan[], network: string) => {
  const superScriptSet = new Set();
  const currentNetwork =
    network === IN_NETWORK.value ? 'inNetwork' : 'outOfNetwork';

  for (const plan of plans) {
    if (plan.customServices) {
      for (const service of plan.customServices) {
        const val = appendSuperScripts(
          service.serviceValue[currentNetwork],
          ''
        );

        if (val.length > 0) superScriptSet.add(val.toString().trim());
      }
    }
    if (plan.rxCosts) {
      const val = appendRxSuperScripts(plan.rxCosts, currentNetwork);
      if (val.length > 0) superScriptSet.add(val);
    }
    if (plan.mailOrderRxCosts) {
      const val = appendRxSuperScripts(plan.mailOrderRxCosts, currentNetwork);
      if (val.length > 0) superScriptSet.add(val);
    }
  }

  return superScriptSet;
};

const getRxValues = (
  object: any = {},
  network: 'IN' | 'OUT',
  type: 'MAIL_ORDER' | 'RETAIL'
) => {
  if (!object) {
    return {};
  } else {
    const cost = new Map();
    const keys = Object?.keys(object) || [];
    keys?.forEach((key) => {
      cost.set(
        `MEDICAL_RX_${key?.replace('_', '')}_${type}_${network}`,
        object?.[key]?.info
      );
    });

    return Object.fromEntries(cost);
  }
};

const getServiceValues = (
  customServices: any[] = [],
  network: 'IN' | 'OUT',
  benefitCategory: 'MEDICAL' | 'DENTAL',
  parameters: Parameter[] = []
) => {
  const networkProperty = network === 'IN' ? 'inNetwork' : 'outOfNetwork';
  if (isEmpty(customServices)) {
    return {};
  } else {
    const cost = new Map();
    customServices?.forEach((service) => {
      let fieldName = `${benefitCategory}_${service?.benefitCode?.shortName
        ?.trim()
        .toUpperCase()
        .replace(/ /g, '_')}_${network}`;
      // map the field name to the key in the parameters
      parameters?.forEach((param: any) => {
        if (param?.shortName === service?.benefitCode?.shortName?.trim() && param?.network === network) {
          fieldName = param?.key;
        }
      });
      cost.set(fieldName, service?.serviceValue?.[networkProperty]?.info);
    });

    return Object.fromEntries(cost);
  }
};

export const getListedMultiplePlanSuperScripts = (
  plans: MDVPlan[],
  benefitCategory: 'MEDICAL' | 'DENTAL'
): MDVPlanSuperscript[] => {
  if (!plans?.length) return [];

  const data: any[] = [];

  const getDeductibleData = (plan: MDVPlan, category: 'MEDICAL' | 'DENTAL') => {
    if (category === 'MEDICAL') {
      return {
        MEDICAL_FAMILY_DEDUCTIBLE_IN: plan?.deductibles?.familyInNetworkCost,
        MEDICAL_FAMILY_DEDUCTIBLE_OUT:
          plan?.deductibles?.familyOutOfNetworkCost,
        MEDICAL_INDIVIDUAL_DEDUCTIBLE_IN:
          plan?.deductibles?.individualInNetworkCost,
        MEDICAL_INDIVIDUAL_DEDUCTIBLE_OUT:
          plan?.deductibles?.individualOutOfNetworkCost,
        MEDICAL_INDIVIDUAL_WITHIN_FAMILY_DEDUCTIBLE_IN:
          plan?.deductibles?.individualWithinFamilyInNetworkCost,
        MEDICAL_INDIVIDUAL_WITHIN_FAMILY_DEDUCTIBLE_OUT:
          plan?.deductibles?.individualWithinFamilyOutOfNetworkCost,
        MEDICAL_INDIVIDUAL_OOP_MAX_IN:
          plan?.outOfPocket?.individualInNetworkCost,
        MEDICAL_INDIVIDUAL_OOP_MAX_OUT:
          plan?.outOfPocket?.individualOutOfNetworkCost,
        MEDICAL_FAMILY_OOP_MAX_IN: plan?.outOfPocket?.familyInNetworkCost,
        MEDICAL_FAMILY_OOP_MAX_OUT: plan?.outOfPocket?.familyOutOfNetworkCost,
        MEDICAL_INDIVIDUAL_WITHIN_FAMILY_OOP_MAX_IN:
          plan?.outOfPocket?.individualWithinFamilyInNetworkCost,
        MEDICAL_INDIVIDUAL_WITHIN_FAMILY_OOP_MAX_OUT:
          plan?.outOfPocket?.individualWithinFamilyOutOfNetworkCost,
      };
    } else {
      return {
        DENTAL_INDIVIDUAL_DEDUCTIBLE_IN:
          plan?.deductibles?.individualInNetworkCost,
        DENTAL_INDIVIDUAL_DEDUCTIBLE_OUT:
          plan?.deductibles?.individualOutOfNetworkCost,
        DENTAL_FAMILY_DEDUCTIBLE_IN: plan?.deductibles?.familyInNetworkCost,
        DENTAL_FAMILY_DEDUCTIBLE_OUT: plan?.deductibles?.familyOutOfNetworkCost,
        DENTAL_PLAN_YEAR_MAX_IN: plan?.calendarYearMax?.inNetworkCost,
        DENTAL_PLAN_YEAR_MAX_OUT: plan?.calendarYearMax?.outOfNetworkCost,
        DENTAL_ORTHODONTIA_MAX_IN: plan?.orthodontiaLifetimeMax?.inNetworkCost,
        DENTAL_ORTHODONTIA_MAX_OUT:
          plan?.orthodontiaLifetimeMax?.outOfNetworkCost,
      };
    }
  };

  const getRxData = (plan: MDVPlan) => ({
    MEDICAL_RX_INDIVIDUAL_DEDUCTIBLE_IN:
      plan?.rxDeductiblesAndOop?.individualDeductibleInNetworkCost,
    MEDICAL_RX_INDIVIDUAL_DEDUCTIBLE_OUT:
      plan?.rxDeductiblesAndOop?.individualDeductibleOutOfNetworkCost,
    MEDICAL_RX_FAMILY_DEDUCTIBLE_IN:
      plan?.rxDeductiblesAndOop?.familyDeductibleInNetworkCost,
    MEDICAL_RX_FAMILY_DEDUCTIBLE_OUT:
      plan?.rxDeductiblesAndOop?.familyDeductibleOutOfNetworkCost,
    MEDICAL_RX_INDIVIDUAL_OOP_MAX_IN:
      plan?.rxDeductiblesAndOop?.individualOopMaxInNetworkCost,
    MEDICAL_RX_INDIVIDUAL_OOP_MAX_OUT:
      plan?.rxDeductiblesAndOop?.individualOopMaxOutOfNetworkCost,
    MEDICAL_RX_FAMILY_OOP_MAX_IN:
      plan?.rxDeductiblesAndOop?.familyOopMaxInNetworkCost,
    MEDICAL_RX_FAMILY_OOP_MAX_OUT:
      plan?.rxDeductiblesAndOop?.familyOopMaxOutOfNetworkCost,
    ...getRxValues(plan?.rxCosts?.inNetwork, 'IN', 'RETAIL'),
    ...getRxValues(plan?.rxCosts?.outOfNetwork, 'OUT', 'RETAIL'),
    ...getRxValues(plan?.mailOrderRxCosts?.inNetwork, 'IN', 'MAIL_ORDER'),
    ...getRxValues(plan?.mailOrderRxCosts?.outOfNetwork, 'OUT', 'MAIL_ORDER'),
  });

  const getServiceData = (plan: MDVPlan, category: 'MEDICAL' | 'DENTAL') => ({
    ...getServiceValues(plan?.customServices, 'IN', category, plan?.planLabels?.parameters),
    ...getServiceValues(plan?.customServices, 'OUT', category, plan?.planLabels?.parameters),
  });

  plans.forEach((plan) => {
    const deductibleData = getDeductibleData(plan, benefitCategory);
    const rxData = getRxData(plan);
    const serviceData = getServiceData(plan, benefitCategory);

    data.push({ ...deductibleData, ...rxData, ...serviceData });
  });

  const filterObjects = (firstArray: any[], secondArray: any[]) => {
    const validEntries: any[] = [];
    firstArray?.forEach((obj) => {
      Object.keys(obj)?.forEach((key) => {
        if (obj?.[key]) {
          // Use filter to find all matches in secondArray
          const matches = secondArray?.flat()?.filter((o) => o?.key === key);
          if (matches.length) {
            validEntries.push(...matches);
          }
        }
      });
    });

    return validEntries;
  };

  const parameters = plans?.map((plan) => plan?.planLabels?.parameters);
  const valueAvailable = filterObjects(data, parameters);

  const uniqueLabels = (items: any[]) => {
    const labelMap = new Map();
    items?.forEach((item) =>
      item?.labelVOs?.forEach((label: any) => {
        labelMap.set(label?.labelId, label);
      })
    );
    return Array.from(labelMap.values());
  };

  const uniqueLabelsArray = uniqueLabels(valueAvailable);

  return uniqueLabelsArray?.sort(
    (a, b) => Number(a?.superscript) - Number(b?.superscript)
  );
};

export const openWebLink = (weblink: string, benefitKind: string) => {
  window.open(addHttp(weblink), '_blank')?.focus();

  trackEvents({
    category: 'View Details',
    action: `view_${benefitKind}_details`,
    label: 'View Holiday and Time Off Details',
  });
};
export const openDocument = (
  id: string,
  reference: string,
  benefitKind: string
) => {
  const documentType = ALL_DOCUMENT_TYPE.includes(reference)
    ? reference
    : 'PLAN_ADDITIONAL_DOCUMENT';
  window
    .open(
      `${baseApi}/v1/plans/${id}/documents?documentType=${documentType}&benefitKind=${benefitKind}&isOpen=true&planDocumentName=${encodeURIComponent(
        reference
      )}`,
      '_blank'
    )
    ?.focus();

  trackEvents({
    category: 'View Details',
    action: `view_${benefitKind}_details`,
    label: 'View Holiday and Time Off Details',
  });
};

export const viewPlanSummary = (
  id: string,
  planDocument: string,
  benefitKind: string
) => {
  window
    .open(
      `${baseApi}/v1/plans/${id}/documents?documentType=${planDocument}&benefitKind=${benefitKind}&isOpen=true`,
      '_blank'
    )
    ?.focus();

  trackEvents({
    category: 'View Details',
    action: `view_${benefitKind}_details`,
    label: `View ${benefitKind} Details`,
  });
};

export const getDocumentDownloadURL = (
  id: string,
  planDocument: string,
  benefitKind: string
) => {
  return `${baseApi}/v1/plans/${id}/documents?documentType=${planDocument}&benefitKind=${benefitKind}`;
};

export const buildPlanRefsFromPlan = (
  plan: Plan,
  benefitCategory: string
): PlanReference => {
  return {
    id: plan.id,
    planId: plan.id,
    planName: plan.name,
    name: plan.name,
    isArchived: plan.archived,
    startDate: plan.startDate,
    endDate: plan.endDate,
    archived: plan.archived,
    revision: plan.revision,
    groups: plan.groups,
    benefitKind: plan.benefitKind,
    benefitCategory: benefitCategory,
    orderNo: plan.orderNo,
    carrier: {
      id: get(plan, 'carrier.id', null),
      name: get(plan, 'carrier.name', null),
    },
    benefitCarrier: {
      id: get(plan, 'benefitCarrier.id', null),
      name: get(plan, 'benefitCarrier.name', null),
    },
  };
};

export const findFrequencyByBenefitKind = (
  frequencyContributions: ContributionFrequency[],
  benefitKind: string
) => {
  return (
    find(frequencyContributions, { benefitKind: benefitKind }) ||
    ({} as ContributionFrequency)
  );
};

export const getEEContributionByFrequency = (
  frequency: string,
  contribution: any
) => {
  if (frequency === FREQUENCIES.MONTHLY.value) {
    return contribution.employeeCost;
  } else if (frequency === FREQUENCIES.BI_WEEKLY.value) {
    return contribution.employeeBiWeeklyCost;
  } else {
    return contribution.employeeSemiMonthlyCost;
  }
};

export const getAgeBandedPlans = (plans: any) => {
  return (
    (!isEmpty(plans) &&
      plans.filter((plan: MDVPlan) =>
        Object.values(plan?.rates || {}).every(
          (rate: any) => rate?.type === AGE_BAND
        )
      )) ||
    []
  );
};

export const getEEContributionField = (frequency: string) => {
  if (frequency === FREQUENCIES.MONTHLY.value) {
    return 'employeeCost';
  } else if (frequency === FREQUENCIES.BI_WEEKLY.value) {
    return 'employeeBiWeeklyCost';
  } else {
    return 'employeeSemiMonthlyCost';
  }
};

export const isRxDeductiblesAvailable = (addedPlanList: MDVPlan[]) => {
  const availability = { inNetwork: false, outNetwork: false };
  addedPlanList?.map((plan: any) => {
    if (
      !['', 'null', null, undefined].includes(
        plan?.rxDeductiblesAndOop?.individualDeductibleInNetworkCost
      ) ||
      !['', 'null', null, undefined].includes(
        plan?.rxDeductiblesAndOop?.familyDeductibleInNetworkCost
      )
    ) {
      availability.inNetwork = true;
    }

    if (
      !['', 'null', null, undefined].includes(
        plan?.rxDeductiblesAndOop?.individualDeductibleOutOfNetworkCost
      ) ||
      !['', 'null', null, undefined].includes(
        plan?.rxDeductiblesAndOop?.familyDeductibleOutOfNetworkCost
      )
    ) {
      availability.outNetwork = true;
    }
  });

  return availability;
};

export const isRxOopAvailable = (addedPlanList: MDVPlan[]) => {
  const availability = { inNetwork: false, outNetwork: false };
  addedPlanList?.map((plan) => {
    if (
      !['', 'null', null, undefined].includes(
        plan?.rxDeductiblesAndOop?.individualOopMaxInNetworkCost
      ) ||
      !['', 'null', null, undefined].includes(
        plan?.rxDeductiblesAndOop?.familyOopMaxInNetworkCost
      )
    ) {
      availability.inNetwork = true;
    }

    if (
      !['', 'null', null, undefined].includes(
        plan?.rxDeductiblesAndOop?.individualOopMaxOutOfNetworkCost
      ) ||
      !['', 'null', null, undefined].includes(
        plan?.rxDeductiblesAndOop?.familyOopMaxOutOfNetworkCost
      )
    ) {
      availability.outNetwork = true;
    }
  });

  return availability;
};

export const showRxRow = (
  title: any,
  addedPlanList: MDVPlan[],
  selectedNetwork: string
) => {
  return (
    (title.fieldName !== 'rxDeductibles' ||
      isRxDeductiblesAvailable(addedPlanList).inNetwork ||
      selectedNetwork !== 'IN_NETWORK') &&
    (title.fieldName !== 'rxDeductibles' ||
      isRxDeductiblesAvailable(addedPlanList).outNetwork ||
      selectedNetwork !== 'OUT_NETWORK') &&
    (title.fieldName !== 'rxOOP' ||
      isRxOopAvailable(addedPlanList).inNetwork ||
      selectedNetwork !== 'IN_NETWORK') &&
    (title.fieldName !== 'rxOOP' ||
      isRxOopAvailable(addedPlanList).outNetwork ||
      selectedNetwork !== 'OUT_NETWORK')
  );
};
/**
 * Enum for maintaining all file-types and corresponding mime-types used the system.
 * Please extend this object if trying to use a file-type not present in the list.
 */
export const MIME_TYPES = {
  PDF: 'application/pdf',
  CSV: 'text/csv',
  XLS: 'application/vnd.ms-excel',
  DOC: 'application/msword',
  PPT: 'application/vnd.ms-powerpoint',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  POTX: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  PPSX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  JPG: 'image/jpg',
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  ZIP: 'application/zip',
} as const;

export const swapElements = (arr: any[], index1: number, index2: number) => {
  const newArray = [...arr];
  [newArray[index1], newArray[index2]] = [newArray[index2], newArray[index1]];
  return newArray;
};
