import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Spin } from 'antd';
import isEmpty from 'lodash/isEmpty';

import PlanCardSectionEditor from 'components/PlanCardSectionEditor/PlanCardSectionEditor';
import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import { BenefitKind } from 'constants/commonConstants';
import TaxAdvantageAccountsPlan from 'model/TaxAdvantageAccountsPlan';
import useBenguide from 'hooks/benguide';
import { useAppSelector } from 'hooks/redux';
import IconEdit from 'images/icon-edit.svg';
import {
  TAX_ADVANTAGED_PLAN_CHANNEL,
  OPEN_TAX_ADVANTAGED_PLAN_EDIT_MODAL,
} from 'modules/clients/UPClient/UPClientConst';
import useUPClient from 'modules/clients/UPClient/useUPClient';
import * as BenguideService from 'services/BenGuideService';
import { ReactComponent as LeftArrowIcon } from 'images/icons/Icon-left-arrow.svg';
import { ReactComponent as RightArrowIcon } from 'images/icons/Icon-right-arrow.svg';
import PlanCardContainer from 'modules/benefitsWellness/components/PlanCardContainer/PlanCardContainer';
import {
  DCAP_FSA_PLAN_CONTENT_TABS,
  FSA_PLAN_CONTENT_TABS,
  LIMITED_FSA_PLAN_CONTENT_TABS,
  TAX_ADVANTAGE_BENEFIT_KIND_TO_SECTION_MAP,
} from 'modules/taxAdvantaged/constants/taxAdvantagedConstants';
import { SectionName } from 'modules/home/constants';
import PlanCardHeader from 'modules/benefitsWellness/components/PlanCardHeader/PlanCardHeader';
import HealthCareFsaPlanInfo from 'modules/taxAdvantaged/components/HealthCareFsaPlanInfo/HealthCareFsaPlanInfo';
import DcapFsaPlanInfo from 'modules/taxAdvantaged/components/DcapFsaPlanInfo/DcapFsaPlanInfo';
import LimitedPurposeFsaPlanInfo from 'modules/taxAdvantaged/components/LimitedPurposeFsaPlanInfo/LimitedPurposeFsaPlanInfo';

import ViewResourcesModal from 'components/ViewResourcesModal/ViewResourcesModal';
import styles from './fsaPlanCard.module.less';

type Props = {
  plan: TaxAdvantageAccountsPlan;
  moveBottom: () => void;
  moveTop: () => void;
  hideTop: boolean;
  hideBottom: boolean;
  renderReorder: boolean;
};

const FsaPlanCard: FC<Props> = (props: Props) => {
  const { plan, moveBottom, moveTop, hideTop, hideBottom, renderReorder } =
    props;

  const [contentLoading, setContentLoading] = useState<boolean>(false);
  const [selectedEditorContent, setSelectedEditorContent] =
    useState<string>('');
  const [editorContent, setEditorContent] = useState<{ [key: string]: string }>(
    {
      [SectionName.FSA_OVERVIEW_TEXT]: '',
      [SectionName.FSA_ELIGIBILITY_TEXT]: '',
      [SectionName.FSA_EXPENSES_TEXT]: '',
      [SectionName.FSA_ADVANTAGES_TEXT]: '',
      [SectionName.FSA_CONSIDERATIONS_TEXT]: '',
    }
  );
  const [planContent, setPlanContent] = useState<{ [key: string]: string }>({
    [SectionName.FSA_OVERVIEW_TEXT]: '',
    [SectionName.FSA_ELIGIBILITY_TEXT]: '',
    [SectionName.FSA_EXPENSES_TEXT]: '',
    [SectionName.FSA_ADVANTAGES_TEXT]: '',
    [SectionName.FSA_CONSIDERATIONS_TEXT]: '',
  });

  const [selectedOption, setSelectedOption] = useState<string>(
    SectionName.FSA_OVERVIEW_TEXT
  );
  const [activeTab, setActiveTab] = useState<string>(
    SectionName.FSA_OVERVIEW_TEXT
  );

  const upClient = useUPClient();
  const { masterId, isEditMode } = useBenguide();
  const [isVisible, setIsVisible] = useState(false);

  const defaultRichMedia = useAppSelector(
    (state) => state.fsa.defaultRichMedia.data
  );

  const planCardEditorRef = useRef<any>();

  const fsaSectionsForBenefitKind = useMemo(() => {
    switch (plan.benefitKind) {
      case TAX_ADVANTAGE_BENEFIT_KIND_TO_SECTION_MAP.HEALTHCARE_FSA.benefitKind:
        return FSA_PLAN_CONTENT_TABS;
      case TAX_ADVANTAGE_BENEFIT_KIND_TO_SECTION_MAP.DEPENDENT_CARE_FSA
        .benefitKind:
        return DCAP_FSA_PLAN_CONTENT_TABS;
      case TAX_ADVANTAGE_BENEFIT_KIND_TO_SECTION_MAP.LIMITED_PURPOSE_FSA
        .benefitKind:
        return LIMITED_FSA_PLAN_CONTENT_TABS;
      default:
        return [];
    }
  }, [plan.benefitKind]);

  const renderHoverMoveOverlay = () => (
    <div className={styles.moveOverlay}>
      {/* Top Section */}
      {!hideTop ? (
        <div className={styles.top} onClick={moveTop}>
          <LeftArrowIcon className={styles.topIcon} />
          <label>Move</label>
        </div>
      ) : (
        <div className={styles.emptyDiv}></div>
      )}

      {/* Bottom Section */}
      {!hideBottom ? (
        <div className={styles.bottom} onClick={moveBottom}>
          <label>Move</label>
          <RightArrowIcon className={styles.bottomIcon} />
        </div>
      ) : (
        <div className={styles.emptyDiv}></div>
      )}
    </div>
  );

  const getContent = useCallback(
    async (masterId: string, planId: string) => {
      setContentLoading(true);
      try {
        const promises = await Promise.all(
          fsaSectionsForBenefitKind.map((section) => {
            const result = BenguideService.getRichMediaContentForPlan(
              masterId,
              section.code,
              planId,
              false
            ).then((response) => ({
              section: section.code,
              data: response.data,
            }));
            return result;
          })
        );
        promises.forEach((sectionData) => {
          setPlanContent((prevState) => ({
            ...prevState,
            [sectionData.section]: sectionData.data.content,
          }));
          setEditorContent((prevState) => ({
            ...prevState,
            [sectionData.section]: sectionData.data.content,
          }));
          if (
            sectionData.section === SectionName.DCAP_FSA_OVERVIEW_TEXT ||
            sectionData.section === SectionName.LIMITED_FSA_OVERVIEW_TEXT ||
            sectionData.section === SectionName.FSA_OVERVIEW_TEXT
          ) {
            setSelectedEditorContent(sectionData.data.content);
            if (planCardEditorRef.current) {
              planCardEditorRef?.current.changeContent(
                sectionData.data.content
              );
            }
          }
        });
      } catch (error) {}
      setContentLoading(false);
    },
    [fsaSectionsForBenefitKind]
  );

  useEffect(() => {
    getContent(masterId, plan.id);
  }, [masterId, plan.id, getContent]);

  useEffect(() => {
    if (!isEmpty(fsaSectionsForBenefitKind)) {
      setSelectedOption(fsaSectionsForBenefitKind[0].code);
      setActiveTab(fsaSectionsForBenefitKind[0].code);
    }
  }, [fsaSectionsForBenefitKind]);

  const getDefaultRichMediaForSection = (selectTab: string) => {
    if (fsaSectionsForBenefitKind.length > 0) {
      const sectionPropertyName = fsaSectionsForBenefitKind.find(
        (section) => section.code === selectTab
      )?.code;
      if (sectionPropertyName && defaultRichMedia[sectionPropertyName]) {
        return defaultRichMedia[sectionPropertyName];
      }
    }
    return '';
  };

  const saveContent = async () => {
    const editedContent = Object.entries(editorContent)
      .filter(([key, value]) => {
        return planContent[key] !== value;
      })
      .map(([key, value]) => ({ section: key, content: value }));
    setContentLoading(true);

    const editPromises = async () => {
      try {
        for (const section of editedContent) {
          await BenguideService.updateRichMediaContent(
            masterId,
            section.section,
            plan.id,
            section.content
          );
          setPlanContent((prevState) => ({
            ...prevState,
            [section.section]: section.content,
          }));
          setEditorContent((prevState) => ({
            ...prevState,
            [section.section]: section.content,
          }));
          if (activeTab === section.section) {
            setSelectedEditorContent(section.content);
          }
        }
      } catch (error) {}
    };
    editPromises();

    setContentLoading(false);
  };

  const updateEditorContent = (data: string) => {
    setSelectedEditorContent(data);
    setEditorContent((prevData) => ({ ...prevData, [selectedOption]: data }));
  };

  const onSelectOptionChange = (section: SectionName) => {
    setSelectedOption(section);
    setSelectedEditorContent(editorContent[section]);
  };

  const resetAll = () => {
    setEditorContent({ ...planContent });
    setSelectedOption(activeTab);
    setSelectedEditorContent(planContent[activeTab]);
  };

  const onTabsChange = (key: string) => {
    setActiveTab(key);
    onSelectOptionChange(key as SectionName);
  };

  const displayPlanInfo = (plan: TaxAdvantageAccountsPlan) => {
    switch (plan.benefitKind) {
      case TAX_ADVANTAGE_BENEFIT_KIND_TO_SECTION_MAP.HEALTHCARE_FSA.benefitKind:
        return <HealthCareFsaPlanInfo plan={plan} />;
      case TAX_ADVANTAGE_BENEFIT_KIND_TO_SECTION_MAP.DEPENDENT_CARE_FSA
        .benefitKind:
        return <DcapFsaPlanInfo plan={plan} />;
      case TAX_ADVANTAGE_BENEFIT_KIND_TO_SECTION_MAP.LIMITED_PURPOSE_FSA
        .benefitKind:
        return <LimitedPurposeFsaPlanInfo plan={plan} />;
    }
  };

  return (
    <PlanCardContainer>
      {renderReorder && (
        <div className={styles.moveWrapper}>
          <EditableHotspot
            alwaysVisible={false}
            className={styles.hotSpotHoverContainer}
            customModal={() => <></>}
            overlayComponent={renderHoverMoveOverlay()}
          >
            <div className={styles.moveArea}></div>
          </EditableHotspot>
        </div>
      )}
      <div className={styles.planDetailsWrapper}>
        <div className={styles.planDetailsContainer}>
          <EditableHotspot
            alwaysVisible={false}
            editText="Edit"
            editIcon={<img src={IconEdit} alt="edit icon" />}
            useChannel={{
              client: upClient,
              channel: TAX_ADVANTAGED_PLAN_CHANNEL,
              event: OPEN_TAX_ADVANTAGED_PLAN_EDIT_MODAL,
              data: plan,
            }}
            className={styles.HotSpot}
          >
            <div className={styles.planDetails}>
              <PlanCardHeader
                planName={plan.name}
                carrier={plan.benefitCarrier}
              />
              <div className={styles.planInfoValues}>
                {displayPlanInfo(plan)}
              </div>
              {(!isEmpty(plan.documents) ||
                !isEmpty(plan.additionalDocuments) ||
                !isEmpty(plan.additionalWeblinks)) && (
                <div
                  className={styles.viewDetailsLink}
                  onClick={() => setIsVisible(true)}
                >
                  Plan Resources
                </div>
              )}
            </div>
          </EditableHotspot>
        </div>
      </div>
      <div className={styles.tabsArea}>
        {contentLoading ? (
          <Spin />
        ) : (
          <PlanCardSectionEditor
            ref={planCardEditorRef}
            activeKey={activeTab}
            onTabClick={onTabsChange}
            editorSelectedTab={selectedOption}
            onEditorTabChange={onSelectOptionChange}
            tabs={fsaSectionsForBenefitKind}
            planContent={planContent}
            isEditMode={isEditMode}
            resetAll={resetAll}
            selectedEditorContent={selectedEditorContent}
            onResetToDefault={() => {}}
            selectedTabDefaultContent={getDefaultRichMediaForSection(
              selectedOption
            )}
            onUpdateContent={updateEditorContent}
            onResetToCurrent={() => {
              setEditorContent({ ...planContent });
            }}
            benguideId={masterId}
            onSaveContent={saveContent}
          />
        )}
      </div>
      <ViewResourcesModal
        plan={plan}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        benefitKind={BenefitKind.FSA.value}
      />
    </PlanCardContainer>
  );
};

export default FsaPlanCard;
