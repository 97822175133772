import { FC, useCallback, useState, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import useUPClient from 'modules/clients/UPClient/useUPClient';
import {
  findFrequencyByBenefitKind,
  getAgeBandedPlans,
  getListedMultiplePlanSuperScripts,
} from 'modules/benefitsWellness/util';
import iconEdit from 'images/icon-edit.svg';
import MDVPlansWrapper from 'modules/benefitsWellness/components/MDVPlansWrapper/MDVPlansWrapper';
import DentalPlansChannel from 'modules/clients/UPClient/channels/DentalPlansChannel';
import AddOrRemovePlans from 'modules/benefitsWellness/components/AddOrRemovePlans/AddOrRemovePlans';
import MDVTypes from 'modules/benefitsWellness/enums/MDVTypes';
import {
  getDentalPlanList,
  saveAndReloadDentalSection,
} from 'modules/benefitsWellness/slices/dentalSlice';
import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import {
  OPEN_PLAN_LABELS_MODAL,
  DENTAL_PLAN_CHANNEL,
} from 'modules/clients/UPClient/UPClientConst';
import { MDVPlanSuperscript } from 'model/LableTypes';
import useBenguide from 'hooks/benguide';
import MDVPlan from 'model/MDVPlan';
import { BENEFIT_CATEGORY, LEGAL_DISCLAIMER } from 'constants/commonConstants';
import NoMDVPlansView from 'components/NoPlansView/NoMDVPlansView';
import DentalCardWrapper from 'modules/benefitsWellness/components/DentalCardWrapper/DentalCardWrapper';
import AlertMessage from 'components/AlertMessage/AlertMessage';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import BenefitGuideRefreshChannel from 'modules/clients/UPClient/channels/BenefitGuideRefreshChannel';
import BenefitGuideNotificationsChannel from 'modules/clients/UPClient/channels/BenefitGuideNotificationsChannel';
import { NotificationContextProvider } from 'context/NotificationContext';
import {
  AGE_BAND,
  FOUR_TIER,
  N_TIER,
} from 'modules/benefitsWellness/constants/BenefitConstants';
import Rate from 'model/Rate';

import styles from './dental.module.less';

const Dental: FC = () => {
  const upClient = useUPClient();
  const { dental } = useAppSelector((state: any) => state);
  const { rateValidations } = useAppSelector((state) => state.benefitWellness);
  const benGuide = useAppSelector((state) => state.benguide.data);
  const { dentalPlanList, dentalSection = [] } = dental;
  const { plans } = dentalSection;
  const dispatch = useAppDispatch();
  const benguide = useBenguide();
  const { masterId, planYearId, employerId, benefitClasses } = benguide;
  const isEditMode = benguide.isEditMode;
  const [isSaveDisable, setSaveDisable] = useState<boolean>(false);
  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);
  const [planListUpdateFlag, setPlanListUpdateFlag] = useState<boolean>(false);

  const updatePlanList = (
    dentalPlanList: MDVPlan[],
    orderByPlanNames: boolean
  ) => {
    setPlanListUpdateFlag(!planListUpdateFlag);
    dispatch(
      saveAndReloadDentalSection(masterId, dentalPlanList, orderByPlanNames)
    );
  };

  const enablePlanRecommender = plans.length > 1;
  const contribFreq = findFrequencyByBenefitKind(
    benguide.contributionFrequencies,
    BENEFIT_CATEGORY.DENTAL.value
  );
  const { enabled } = contribFreq;

  const reloadAllDentalPlans = useCallback(() => {
    dispatch(getDentalPlanList(employerId, planYearId, benefitClasses));
  }, [employerId, planYearId, benefitClasses, dispatch]);

  const planLabels = useMemo(
    () => getListedMultiplePlanSuperScripts(plans, 'DENTAL'),
    [plans]
  );

  const constructDataForAddPlanLabelModal = (plans: MDVPlan[]) => {
    return {
      employerId: benGuide?.employerId,
      planYear: {
        id: benGuide?.planYearId,
      },
      planYearId: benGuide?.planYearId,
      planIds: plans.map((p) => p?.id),
    };
  };

  const renderPlanLabels = (
    plans: MDVPlan[],
    planLabels: MDVPlanSuperscript[]
  ) => {
    const hasAnyPlanLabelParameters = plans?.some(
      (plan: MDVPlan) => plan?.planLabels?.parameters.length || 0 > 0
    );
    return (
      <div className={styles.bottomText}>
        {plans?.length > 0 &&
          (planLabels?.length > 0 ? (
            <EditableHotspot
              editText="Edit"
              editIcon={<img src={iconEdit} alt="edit" />}
              alwaysVisible={false}
              className={styles.hotSpot}
              useChannel={{
                client: upClient,
                channel: DENTAL_PLAN_CHANNEL,
                event: OPEN_PLAN_LABELS_MODAL,
                data: constructDataForAddPlanLabelModal(plans),
              }}
            >
              {planLabels?.map(
                (
                  { labelName, superscript }: MDVPlanSuperscript,
                  index: number
                ) => (
                  <div key={index}>
                    <sup>{superscript}</sup>
                    {labelName}
                  </div>
                )
              )}
            </EditableHotspot>
          ) : isEditMode ? (
            <EditableHotspot
              alwaysVisible={true}
              useChannel={{
                client: upClient,
                channel: DENTAL_PLAN_CHANNEL,
                event: OPEN_PLAN_LABELS_MODAL,
                data: constructDataForAddPlanLabelModal(plans),
              }}
            >
              <LinkButton onClick={() => {}}>
                {hasAnyPlanLabelParameters ? 'Manage Labels' : 'Create Label'}
              </LinkButton>
            </EditableHotspot>
          ) : (
            <></>
          ))}
        {LEGAL_DISCLAIMER}
      </div>
    );
  };

  const showAlert = () => {
    setIsAlertVisible(true);
  };

  const getAlert = () => (
    <AlertMessage
      className={styles.alert}
      type="success"
      message={'Successfully created new plan.'}
      closeAlert={() => setIsAlertVisible(false)}
      wrapperClassName={styles.planAlertWrapper}
    />
  );

  const foundRates =
    (!isEmpty(plans) &&
      plans
        .map((plan: MDVPlan) => plan.rates)
        .filter((plan: MDVPlan) => plan)
        .map((rate: Rate) => {
          return Object.values(rate);
        })
        .reduce((arr1: [], arr2: []) => {
          return arr1.concat(arr2);
        }, [])
        .map((rate: Rate) => rate.type)) ||
    [];

  const rateType = foundRates.includes(N_TIER)
    ? N_TIER
    : foundRates.includes(FOUR_TIER)
    ? FOUR_TIER
    : AGE_BAND;

  return (
    <NotificationContextProvider isDisableSave={isSaveDisable}>
      {isAlertVisible && getAlert()}
      <div className={styles.dentalWrapper}>
        <DentalPlansChannel />
        <BenefitGuideRefreshChannel benefitKind={MDVTypes.DENTAL} />
        <BenefitGuideNotificationsChannel
          setDisableSave={() => setSaveDisable(true)}
        />
        <MDVPlansWrapper
          planType={MDVTypes.DENTAL}
          addedPlansCount={plans.length}
          enablePlanRecommender={enablePlanRecommender}
          showPlanRecommender={false}
          disablePlanRecommenderOnEdit={!(plans.length > 1)}
          hasMismatchContributions={
            rateValidations.hasMismatchContributions ||
            !enabled ||
            getAgeBandedPlans(plans).length === plans.length
          }
          rateType={rateType}
          planListUpdateFlag={planListUpdateFlag}
        />
        {plans.length > 0 ? (
          <DentalCardWrapper
            onUpdatePlanList={updatePlanList}
            allPlans={dentalPlanList.data}
            reloadPlans={reloadAllDentalPlans}
            showAlert={showAlert}
            isLoading={dentalPlanList.inProgress}
          />
        ) : isEditMode ? (
          <AddOrRemovePlans
            visible={true}
            withoutModal={true}
            title="Dental"
            onSave={updatePlanList}
            addedPlanList={plans}
            allPlans={dentalPlanList.data}
            reloadPlans={reloadAllDentalPlans}
            isLoading={dentalPlanList.inProgress}
            channel={DENTAL_PLAN_CHANNEL}
            showAlert={showAlert}
          />
        ) : (
          <NoMDVPlansView />
        )}
        {renderPlanLabels(plans, planLabels)}
      </div>
    </NotificationContextProvider>
  );
};

export default Dental;
