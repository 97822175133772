import { FC, useEffect, useMemo, useState } from 'react';
import { Space, Spin } from 'antd';

import isEmpty from 'lodash/isEmpty';
import useBenguide from 'hooks/benguide';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { changePlanOrder } from 'modules/benefitsWellness/util';
import MDVPlan from 'model/MDVPlan';
import MDVTypes from 'modules/benefitsWellness/enums/MDVTypes';
import iconEdit from 'images/icon-edit.svg';
import leftRoundIcon from 'images/icons/icon-left-round-arrow.svg';
import rightRoundIcon from 'images/icons/icon-right-round-arrow.svg';
import { BENEFIT_CATEGORY } from 'constants/commonConstants';
import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import MDVCardTitles from 'modules/benefitsWellness/components/MDVCardTitles/MDVCardTitles';
import MDVCardValues from 'modules/benefitsWellness/components/MDVCardValues/MDVCardValues';
import MissingInfo from 'modules/benefitsWellness/components/MissingInfo/MissingInfo';
import NoPlansText from 'modules/benefitsWellness/components/NoPlansText/NoPlansText';
import { listHRAPlans } from 'modules/taxAdvantaged/slices/hraSlice';

import { DV_PLAN_DOCUMENT_TYPE } from 'modules/resources/constants';
import PlanDocuments from 'modules/resources/components/PlanDocuments/PlanDocuments';
import ViewResourcesModal from 'components/ViewResourcesModal/ViewResourcesModal';
import styles from './visionCardWrapper.module.less';

type VisionCardWrapperProps = {
  onUpdatePlanList: Function;
  allPlans: MDVPlan[];
  reloadPlans: Function;
  showAlert: Function;
  isLoading: boolean;
};

const VisionCardWrapper: FC<VisionCardWrapperProps> = (
  props: VisionCardWrapperProps
) => {
  const { onUpdatePlanList, reloadPlans, allPlans, showAlert, isLoading } =
    props;
  const dispatch = useAppDispatch();
  const { vision, benefitWellness } = useAppSelector((state: any) => state);
  const { isTablet, isMobile } = useAppSelector((state) => state.layout);

  const { activePlanCompare, selectedPlanForCompare } = benefitWellness;
  const { inProgress, filteredPlanList } = vision;
  const benguide = useBenguide();
  const isEditMode = benguide.isEditMode;
  const plans = useMemo(() => {
    if (activePlanCompare) {
      return filteredPlanList.filter((plan: MDVPlan) =>
        selectedPlanForCompare.includes(plan.id)
      ) as MDVPlan[];
    } else {
      return filteredPlanList;
    }
  }, [activePlanCompare, filteredPlanList, selectedPlanForCompare]);

  const [planViewCursor, setPlanViewCursor] = useState<number>(0);
  const [planDetailsHeight, setPlanDetailsHeight] = useState(82);

  useEffect(() => {
    setPlanViewCursor(0);
  }, [plans.length]);

  useEffect(() => {
    setPlanDetailsHeight(82);
  }, [plans]);

  useEffect(() => {
    const { planYearId, employerId, benefitClasses } = benguide;
    dispatch(listHRAPlans(employerId, planYearId, benefitClasses));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const maxPlanCards = useMemo(() => {
    if (isTablet && !isMobile) {
      return 2;
    }
    if (isMobile) {
      return plans.length;
    }
    return 4;
  }, [isTablet, isMobile, plans?.length]);

  if (inProgress) {
    return (
      <Space size="middle">
        <Spin size="large" />
      </Space>
    );
  }

  return (
    <div
      className={`${styles.visionCardWrapper} ${
        !isEditMode ? styles.customMargin : ''
      }`}
    >
      {planViewCursor !== 0 && plans.length > 0 && (
        <img
          className={styles.leftRoundIcon}
          src={leftRoundIcon}
          alt="left"
          onClick={() => {
            setPlanViewCursor(planViewCursor - 1);
          }}
        />
      )}

      {maxPlanCards + planViewCursor < plans.length && (
        <img
          className={styles.rightRoundIcon}
          src={rightRoundIcon}
          alt="right"
          onClick={() => {
            setPlanViewCursor(planViewCursor + 1);
          }}
        />
      )}

      {plans.length > 0 ? (
        <MDVCardTitles
          planType={MDVTypes.VISION}
          onUpdatePlanList={onUpdatePlanList}
          addedPlanList={plans}
          allPlans={allPlans}
          reloadPlans={reloadPlans}
          allowHeaderTitle={true}
          showAlert={showAlert}
          isLoading={isLoading}
          detailsHeight={planDetailsHeight}
        />
      ) : (
        <NoPlansText text={'There are no plans that fit your criteria.'} />
      )}

      {plans
        .slice(planViewCursor, maxPlanCards + planViewCursor)
        .map((plan: any, index: number) => (
          <MDVCardValues
            key={index}
            plan={plan}
            planType={MDVTypes.VISION}
            planIds={plans?.map((plan: any) => plan?.id)}
            attachedPlans={plans}
            moveLeft={() => {
              changePlanOrder(
                index + planViewCursor,
                index + planViewCursor - 1,
                plans,
                onUpdatePlanList
              );
            }}
            moveRight={() => {
              changePlanOrder(
                index + planViewCursor,
                index + planViewCursor + 1,
                plans,
                onUpdatePlanList
              );
            }}
            hideLeftMoveButton={
              index % maxPlanCards === 0 && planViewCursor === 0
            }
            hideRightMoveButton={index + planViewCursor === plans.length - 1}
            summarySection={
              <VisionSummarySection plan={plan} attachedPlans={plans} />
            }
            onHigherDetailsHeight={(number) => {
              setPlanDetailsHeight(number);
            }}
            detailsHeight={planDetailsHeight}
          />
        ))}
    </div>
  );
};

type VisionSummarySectionProps = {
  plan: MDVPlan;
  attachedPlans: MDVPlan[];
};

const VisionSummarySection: FC<VisionSummarySectionProps> = ({
  plan,
}: VisionSummarySectionProps) => {
  const benguide = useBenguide();
  const isEditMode = benguide.isEditMode;
  const [isVisible, setIsVisible] = useState(false);

  if (
    isEditMode ||
    !isEmpty(plan.documents) ||
    !isEmpty(plan.additionalDocuments) ||
    !isEmpty(plan.additionalWeblinks)
  ) {
    return (
      <>
        <EditableHotspot
          alwaysVisible={false}
          editText="Edit"
          editIcon={<img src={iconEdit} alt="edit" />}
          customModal={(visible: boolean, onClose: Function) => (
            <PlanDocuments
              visible={visible}
              onClose={onClose}
              benefitKind={BENEFIT_CATEGORY.VISION.value}
              planDocumentTypes={DV_PLAN_DOCUMENT_TYPE}
              plan={plan}
            />
          )}
        >
          {!isEmpty(plan.documents) ||
          !isEmpty(plan.additionalDocuments) ||
          !isEmpty(plan.additionalWeblinks) ? (
            <div className={styles.planSummaryWrapper}>
              <div className={styles.viewSummary}>
                <span
                  className={styles.sbcLink}
                  onClick={() => setIsVisible(true)}
                >
                  Plan Resources
                </span>
              </div>
            </div>
          ) : isEditMode ? (
            <div className={styles.missingInfo}>
              <MissingInfo missingInfoText="Missing Summary" />
            </div>
          ) : (
            <div className={styles.emptySummary}>-</div>
          )}
        </EditableHotspot>
        <ViewResourcesModal
          plan={plan}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          benefitKind={BENEFIT_CATEGORY.VISION.value}
        />
      </>
    );
  }
  return <></>;
};

export default VisionCardWrapper;
