import { isNullOrUndefined } from 'util';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';

import isEmpty from 'lodash/isEmpty';
import { Col, Row, Spin } from 'antd';
import MissingBenefitValue from 'components/MissingBenefitValue/MissingBenefitValue';
import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import TooltipPopover from 'components/TooltipPopover/TooltipPopover';
import LifePlan from 'model/LifePlan';
import { ReactComponent as LeftArrowIcon } from 'images/icons/Icon-left-arrow.svg';
import { ReactComponent as RightArrowIcon } from 'images/icons/Icon-right-arrow.svg';
import {
  LIFE_PLAN_TYPES,
  VOLUNTARY_LIFE_PLAN_TYPES,
} from 'modules/benefitsWellness/constants/BenefitConstants';
import PlanCardContainer from 'modules/benefitsWellness/components/PlanCardContainer/PlanCardContainer';
import iconEdit from 'images/icon-edit.svg';
import PlanCardEditor from 'modules/benefitsWellness/components/PlanCardEditor';
import useBenguide from 'hooks/benguide';
import { useAppSelector } from 'hooks/redux';
import {
  getRichMediaContent,
  updateRichMediaContent,
} from 'services/BenGuideService';
import ContentPreviewer from 'components/ContentPreviewer/ContentPreviewer';
import useUPClient from 'modules/clients/UPClient/useUPClient';
import {
  LIFE_AND_DISABILITY_CHANNEL,
  OPEN_LIFE_AND_DISABILITY_PLAN_EDIT_MODAL,
} from 'modules/clients/UPClient/UPClientConst';
import { LIFE_AND_DISABILITY_CARD_TOOLTIPS } from 'modules/benefitsWellness/constants/TooltipConstants';
import PlanCardHeader from 'modules/benefitsWellness/components/PlanCardHeader/PlanCardHeader';
import { SectionName } from 'modules/home/constants';

import ViewResourcesModal from 'components/ViewResourcesModal/ViewResourcesModal';
import styles from './lifeAndDisabilityCard.module.less';

type Props = {
  plan: LifePlan;
  moveBottom: () => void;
  moveTop: () => void;
  hideTop: boolean;
  hideBottom: boolean;
  renderReorder: boolean;
};

const formatNumberString = (numberString: string | undefined): string => {
  const number = parseFloat(numberString || '');
  if (
    !isNaN(number) &&
    numberString?.indexOf('.') !== -1 &&
    Number.isInteger(number * 10)
  ) {
    return number.toFixed(2);
  }
  return numberString || '';
};

const LifeAndDisabilityCard: FC<Props> = (props: Props) => {
  const { plan, moveBottom, moveTop, hideTop, hideBottom, renderReorder } =
    props;
  const defaultRichMedia = useAppSelector(
    (state) => state.lifeAndDisability.defaultRichMedia.data
  );
  const { isMobile } = useAppSelector((state) => state.layout);
  const [isVisible, setIsVisible] = useState(false);
  const [contentLoading, setContentLoading] = useState(false);
  const [content, setContent] = useState('');
  const [editorContent, setEditorContent] = useState('');
  const planCardEditorRef = useRef<any>();

  const { benefitKind } = plan;
  const { masterId } = useBenguide();
  const upClient = useUPClient();

  useEffect(() => {
    if (masterId && plan.id) {
      getContent(masterId, plan.id, plan.benefitKind);
    }
  }, [masterId, plan.id, plan.benefitKind]);

  const getContent = async (
    masterId: string,
    planId: string,
    benefitKind: string
  ) => {
    setContentLoading(true);
    try {
      const response = await getRichMediaContent(masterId, benefitKind, planId);
      setContent(response.data.content);
      setEditorContent(response.data.content);
    } catch (error) {}
    setContentLoading(false);
  };

  const handleSaveContent = async (content: string) => {
    setContentLoading(true);
    try {
      await updateRichMediaContent(
        masterId,
        plan.benefitKind,
        plan.id,
        content
      );
      setContent(content);
    } catch (error) {}
    setContentLoading(false);
  };

  const renderHoverMoveOverlay = () => (
    <div className={styles.moveOverlay}>
      {/* Top Section */}
      {!hideTop ? (
        <div className={styles.top} onClick={moveTop}>
          <LeftArrowIcon className={styles.topIcon} />
          <label>Move</label>
        </div>
      ) : (
        <div className={styles.emptyDiv}></div>
      )}

      {/* Bottom Section */}
      {!hideBottom ? (
        <div className={styles.bottom} onClick={moveBottom}>
          <label>Move</label>
          <RightArrowIcon className={styles.bottomIcon} />
        </div>
      ) : (
        <div className={styles.emptyDiv}></div>
      )}
    </div>
  );

  const getDefaultRichMediaForSection = () => {
    let sectionName = SectionName.BASIC_LIFE;
    if (benefitKind === LIFE_PLAN_TYPES.BASIC_ADD.value) {
      sectionName = SectionName.BASIC_ADD;
    } else if (benefitKind === LIFE_PLAN_TYPES.BASIC_LIFE_AND_ADD.value) {
      sectionName = SectionName.BASIC_LIFE_AND_ADD;
    } else if (benefitKind === LIFE_PLAN_TYPES.STD.value) {
      sectionName = SectionName.STD;
    } else if (benefitKind === LIFE_PLAN_TYPES.LTD.value) {
      sectionName = SectionName.LTD;
    } else if (benefitKind === VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_ADD.value) {
      sectionName = SectionName.VOLUNTARY_ADD;
    } else if (benefitKind === VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_LIFE.value) {
      sectionName = SectionName.VOLUNTARY_LIFE;
    } else if (
      benefitKind === VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_LIFE_ADD.value
    ) {
      sectionName = SectionName.VOLUNTARY_LIFE_ADD;
    } else if (benefitKind === VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_STD.value) {
      sectionName = SectionName.VOLUNTARY_STD;
    } else if (benefitKind === VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_LTD.value) {
      sectionName = SectionName.VOLUNTARY_LTD;
    }
    if (sectionName && defaultRichMedia[sectionName]) {
      return defaultRichMedia[sectionName];
    }
    return '';
  };

  const handleResetDefault = () => {
    const defaultContent = getDefaultRichMediaForSection();
    setEditorContent(defaultContent);
    planCardEditorRef.current.changeContent(defaultContent);
  };

  const setCurrentConetnt = () => {
    setEditorContent(content);
  };

  return (
    <PlanCardContainer className={styles.container}>
      {renderReorder && (
        <div className={styles.moveWrapper}>
          <EditableHotspot
            alwaysVisible={false}
            className={styles.hotSpotHoverContainer}
            customModal={() => <></>}
            overlayComponent={renderHoverMoveOverlay()}
          >
            <div className={styles.moveArea}></div>
          </EditableHotspot>
        </div>
      )}

      <>
        <div className={styles.summaryWrapper}>
          <EditableHotspot
            alwaysVisible={false}
            editText="Edit"
            editIcon={<img src={iconEdit} alt="edit" />}
            className={styles.hotSpot}
            useChannel={{
              client: upClient,
              channel: LIFE_AND_DISABILITY_CHANNEL,
              event: OPEN_LIFE_AND_DISABILITY_PLAN_EDIT_MODAL,
              data: plan,
            }}
          >
            <div className={styles.summary}>
              <PlanCardHeader
                planName={plan.name}
                carrier={plan.benefitCarrier}
                benefitType={plan.benefitKind}
              />
              {(!isEmpty(plan.documents) ||
                !isEmpty(plan.additionalDocuments) ||
                !isEmpty(plan.additionalWeblinks)) && (
                <a
                  className={styles.detailsLink}
                  href="#top"
                  onClick={() => setIsVisible(true)}
                >
                  Plan Resources
                </a>
              )}
            </div>
          </EditableHotspot>
        </div>
        <div className={styles.detailsSection}>
          <EditableHotspot
            editText="Edit"
            editIcon={<img src={iconEdit} alt="edit" />}
            alwaysVisible={false}
            className={styles.hotSpot}
            customModal={(
              visible: boolean,
              onClose: Function,
              editorKey: string
            ) => (
              // TODO: handle reset to default and empty content
              <PlanCardEditor
                isOpenModal={visible}
                closeModal={onClose}
                editorContent={editorContent}
                isResetEnabled={true}
                resetToCurrentValue={() => {}}
                resetToDefault={handleResetDefault}
                defaultContent={getDefaultRichMediaForSection()}
                updateEditorContent={setEditorContent}
                benguideId={masterId}
                onSubmit={handleSaveContent}
                ref={planCardEditorRef}
                setCurrentConetnt={setCurrentConetnt}
                editorKey={editorKey}
              />
            )}
          >
            <div className={styles.content}>
              {contentLoading ? (
                <Spin />
              ) : (
                <ContentPreviewer htmlContent={content} />
              )}
            </div>
          </EditableHotspot>
          <EditableHotspot
            alwaysVisible={false}
            editText="Edit"
            editIcon={<img src={iconEdit} alt="edit" />}
            useChannel={{
              client: upClient,
              channel: LIFE_AND_DISABILITY_CHANNEL,
              event: OPEN_LIFE_AND_DISABILITY_PLAN_EDIT_MODAL,
              data: plan,
            }}
          >
            {(plan.benefitKind === LIFE_PLAN_TYPES.BASIC_ADD.value ||
              plan.benefitKind === LIFE_PLAN_TYPES.BASIC_LIFE.value ||
              plan.benefitKind ===
                LIFE_PLAN_TYPES.BASIC_LIFE_AND_ADD.value) && (
              <BasicLifePlanDetails plan={plan} />
            )}
            {(plan.benefitKind === LIFE_PLAN_TYPES.LTD.value ||
              plan.benefitKind === LIFE_PLAN_TYPES.STD.value ||
              plan.benefitKind ===
                VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_STD.value ||
              plan.benefitKind ===
                VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_LTD.value) && (
              <DisabilityPlanDetails plan={plan} />
            )}
            {(plan.benefitKind ===
              VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_ADD.value ||
              plan.benefitKind ===
                VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_LIFE.value ||
              plan.benefitKind ===
                VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_LIFE_ADD.value) && (
              <VoluntaryPlanDetails plan={plan} isMobile={isMobile} />
            )}
          </EditableHotspot>
        </div>
        <ViewResourcesModal
          plan={plan}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          benefitKind={benefitKind}
        />
      </>
    </PlanCardContainer>
  );
};

export default LifeAndDisabilityCard;

const PlanDetail = (props: {
  header: string;
  value?: string | number;
  text?: string;
  tooltipContent: string | ReactNode;
  className?: string;
}) => {
  const { header, value, text, tooltipContent, className = '' } = props || null;

  return (
    <div className={`${styles.detailSection} ${className}`}>
      <div className={styles.detailLabel}>
        <TooltipPopover
          placement="top"
          className="addPlansOverlay"
          content={tooltipContent}
        >
          {header}
        </TooltipPopover>
      </div>

      {value !== null && value !== undefined ? (
        !text ? (
          <MissingBenefitValue />
        ) : (
          <div className={styles.detailValue}>{text}</div>
        )
      ) : (
        <MissingBenefitValue />
      )}
    </div>
  );
};

type BasicLifePlanDetailsProps = {
  plan: LifePlan;
  isMobile?: boolean;
};

const BasicLifePlanDetails = (props: BasicLifePlanDetailsProps) => {
  const { plan } = props;

  const getBenefitDetail = (plan: LifePlan) => {
    const benefitText =
      !isNullOrUndefined(plan.benefitFlatAmount) &&
      plan.benefitFlatAmount !== '' &&
      !isNullOrUndefined(plan.benefitMultiplier) &&
      plan.benefitMultiplier !== ''
        ? `${plan.benefitMultiplier?.toLocaleString()}${
            !isNullOrUndefined(plan.lifeBenefit)
              ? 'x Annual Salary for Life'
              : ''
          } + ${formatNumberString(plan.benefitFlatAmount?.toLocaleString())}`
        : !isNullOrUndefined(plan.benefitFlatAmount) &&
          plan.benefitFlatAmount !== ''
        ? `${formatNumberString(plan.benefitFlatAmount?.toLocaleString())}`
        : !isNullOrUndefined(plan.benefitMultiplier) &&
          plan.benefitMultiplier !== ''
        ? `${plan.benefitMultiplier?.toLocaleString()}${
            !isNullOrUndefined(plan.lifeBenefit)
              ? 'x Annual Salary for Life'
              : ''
          } `
        : '';

    return (
      <PlanDetail
        header="Benefit"
        value={plan.benefitMultiplier && plan.benefitFlatAmount}
        text={benefitText}
        tooltipContent={LIFE_AND_DISABILITY_CARD_TOOLTIPS.benefit}
      />
    );
  };

  return (
    <div className={styles.planDetails}>
      {getBenefitDetail(plan)}
      <PlanDetail
        header="Benefit Maximum"
        text={`${formatFreeText(plan.benefitMaximum?.toLocaleString())}`}
        value={plan.benefitMaximum}
        tooltipContent={LIFE_AND_DISABILITY_CARD_TOOLTIPS.maxBenefit}
      />
      <PlanDetail
        header="Guaranteed Issue"
        value={plan.guaranteedIssue}
        text={`${formatFreeText(plan.guaranteedIssue?.toLocaleString()) || 0}`}
        tooltipContent={LIFE_AND_DISABILITY_CARD_TOOLTIPS.guaranteedIssue}
      />
    </div>
  );
};

const DisabilityPlanDetails = (props: BasicLifePlanDetailsProps) => {
  const { plan } = props;

  const isSTD =
    plan.benefitKind === LIFE_PLAN_TYPES.STD.value ||
    plan.benefitKind === VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_STD.value;

  const isLTD =
    plan.benefitKind === LIFE_PLAN_TYPES.LTD.value ||
    plan.benefitKind === VOLUNTARY_LIFE_PLAN_TYPES.VOLUNTARY_LTD.value;

  return (
    <div className={styles.planDetails}>
      <PlanDetail
        header="Benefit"
        text={
          plan.benefitPercentage?.toLocaleString() &&
          `${plan.benefitPercentage}`
        }
        value={plan.benefitPercentage}
        tooltipContent={LIFE_AND_DISABILITY_CARD_TOOLTIPS.benefit}
      />
      {isSTD && (
        <PlanDetail
          header="Max Weekly Benefit"
          text={`${formatNumberString(
            plan.weeklyBenefitMax?.toLocaleString()
          )}`}
          value={plan.weeklyBenefitMax}
          tooltipContent={LIFE_AND_DISABILITY_CARD_TOOLTIPS.maxWeeklyBenefit}
        />
      )}
      {isLTD && (
        <PlanDetail
          header="Max Monthly Benefit"
          text={`${formatNumberString(
            plan.monthlyBenefitMax?.toLocaleString()
          )}`}
          value={plan.monthlyBenefitMax}
          tooltipContent={LIFE_AND_DISABILITY_CARD_TOOLTIPS.maxMonthlyBenefit}
        />
      )}
      <PlanDetail
        header="Waiting Period"
        text={plan.waitingPeriod && `${plan.waitingPeriod}`}
        value={plan.waitingPeriod}
        tooltipContent={LIFE_AND_DISABILITY_CARD_TOOLTIPS.waitingPeriod}
      />
      {isSTD && (
        <PlanDetail
          header="Max Benefit Duration"
          text={`${plan.benefitDurationSTD}`}
          value={plan.benefitDurationSTD}
          tooltipContent={LIFE_AND_DISABILITY_CARD_TOOLTIPS.maxBenefitDuration}
        />
      )}
      {isLTD && (
        <PlanDetail
          header="Max Benefit Duration"
          text={`${plan.benefitDurationLTD}`}
          value={plan.benefitDurationLTD}
          tooltipContent={LIFE_AND_DISABILITY_CARD_TOOLTIPS.maxBenefitDuration}
          className={styles.ltdMaxDuration}
        />
      )}
    </div>
  );
};

const VoluntaryPlanDetails = (props: BasicLifePlanDetailsProps) => {
  const { plan, isMobile } = props;

  const {
    employeeBenefit,
    employeeGuaranteedIssue,
    spouseBenefit,
    spouseGuaranteedIssue,
    childBenefit,
    childGuaranteedIssue,
  } = plan || {};

  return (
    <div style={{ display: 'block', width: '100%' }}>
      {!isMobile ? (
        <Col className={styles.volPlanDetailsTable}>
          <Row gutter={[16, 0]}>
            <Col span={4}></Col>

            <Col span={13} offset={1}>
              <TooltipPopover
                placement="top"
                className="addPlansOverlay"
                content={LIFE_AND_DISABILITY_CARD_TOOLTIPS.volBenefit}
              >
                Benefit
              </TooltipPopover>
            </Col>

            <Col span={6}>
              <TooltipPopover
                placement="top"
                className="addPlansOverlay"
                content={LIFE_AND_DISABILITY_CARD_TOOLTIPS.volGuaranteedIssue}
              >
                Guaranteed Issue
              </TooltipPopover>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col span={4}>Employee</Col>
            <Col span={13} offset={1}>
              {employeeBenefit || <MissingBenefitValue />}
            </Col>
            <Col span={6}>
              {displayGuaranteedIssue(employeeGuaranteedIssue)}
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col span={4}>Spouse</Col>
            <Col span={13} offset={1}>
              {spouseBenefit || <MissingBenefitValue />}
            </Col>
            <Col span={6}>{displayGuaranteedIssue(spouseGuaranteedIssue)}</Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col span={4}>Child</Col>
            <Col span={13} offset={1}>
              {childBenefit || <MissingBenefitValue />}
            </Col>
            <Col span={6}>{displayGuaranteedIssue(childGuaranteedIssue)}</Col>
          </Row>
        </Col>
      ) : (
        <div className={styles.volPlanDetailsMobile}>
          <VolBenefitSectionMobile
            header="Employee"
            benefitAmount={employeeBenefit}
            guaranteedIssue={employeeGuaranteedIssue}
          />
          <VolBenefitSectionMobile
            header="Spouse"
            benefitAmount={spouseBenefit}
            guaranteedIssue={spouseGuaranteedIssue}
          />
          <VolBenefitSectionMobile
            header="Child"
            benefitAmount={childBenefit}
            guaranteedIssue={childGuaranteedIssue}
          />
        </div>
      )}
    </div>
  );
};

const displayGuaranteedIssue = (value: string | number | undefined) => {
  if (typeof value === 'number') {
    return `$${formatNumberString(value?.toLocaleString())}`;
  } else if (typeof value === 'string') {
    const numberValue = parseFloat(value?.toLocaleString());
    if (!isNaN(numberValue)) {
      return `$${formatNumberString(value?.toLocaleString())}`;
    } else {
      return value;
    }
  } else {
    return <MissingBenefitValue />;
  }
};

const formatFreeText = (value: string | number | undefined) => {
  if (typeof value === 'number') {
    return `$${formatNumberString(value?.toLocaleString())}`;
  } else if (typeof value === 'string') {
    const numberValue = parseFloat(value?.toLocaleString());
    if (!isNaN(numberValue)) {
      return `$${formatNumberString(value?.toLocaleString())}`;
    } else {
      return value;
    }
  } else {
    return <MissingBenefitValue />;
  }
};

const VolBenefitSectionMobile = ({
  header,
  benefitAmount,
  guaranteedIssue,
}: {
  header: string;
  benefitAmount: string | undefined;
  guaranteedIssue: number | undefined;
}) => {
  return (
    <div className={styles.benefitSection}>
      <Row>
        <Col className={styles.volRowHeader}>{header}</Col>
      </Row>
      <Row>
        <Col className={styles.volColHeader} span={6}>
          Benefit
        </Col>
        <Col offset={1} span={17}>
          {benefitAmount || <MissingBenefitValue />}
        </Col>
      </Row>
      <Row>
        <Col className={styles.volColHeader} span={6}>
          Guaranteed Issue
        </Col>
        <Col offset={1} span={17}>
          {displayGuaranteedIssue(guaranteedIssue)}
        </Col>
      </Row>
    </div>
  );
};
