import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import useBenguide from 'hooks/benguide';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import SavePlanDescriptionModal from 'components/SavePlanDescriptionModal/SavePlanDescriptionModal';

import PlanCardEditor from 'modules/benefitsWellness/components/PlanCardEditor';
import useUPClient from 'modules/clients/UPClient/useUPClient';
import ContentPreviewer from 'components/ContentPreviewer/ContentPreviewer';
import { ReactComponent as LeftArrowIcon } from 'images/icons/Icon-left-arrow.svg';
import { ReactComponent as RightArrowIcon } from 'images/icons/Icon-right-arrow.svg';
import {
  fetchWellbeingPlanList,
  getRichMediaContent,
  reloadWellbeingSection,
  updateRichMediaContent,
} from 'modules/benefitsWellness/slices/wellbeingSlice';

import {
  WELLBEING_PLAN_CHANNEL,
  OPEN_WELLBEING_PLAN_EDIT_MODAL,
} from 'modules/clients/UPClient/UPClientConst';

import iconEdit from 'images/icon-edit.svg';
import WellbeingPlan from 'model/WellbeingPlan';
import TooltipPopover from 'components/TooltipPopover/TooltipPopover';
import Spinner from 'components/Spinner/Spinner';
import AddYourText from 'modules/benefitsWellness/components/AddYourText/AddYourText';
import PlanCardContainer from 'modules/benefitsWellness/components/PlanCardContainer/PlanCardContainer';
import BenefitCarrier from 'model/BenefitCarrier';
import ViewResourcesModal from 'components/ViewResourcesModal/ViewResourcesModal';
import styles from './wellbeingCard.module.less';

type WellbeingCardProps = {
  plan: WellbeingPlan;
  moveBottom: () => void;
  moveTop: () => void;
  hideTop: boolean;
  hideBottom: boolean;
};

const WellbeingCard: FC<WellbeingCardProps> = ({
  plan,
  moveBottom,
  moveTop,
  hideTop,
  hideBottom,
}: WellbeingCardProps) => {
  const upClient = useUPClient();

  const benguide = useBenguide();
  const dispatch = useAppDispatch();
  const { isTablet } = useAppSelector((state) => state.layout);
  const { richMedia } = useAppSelector((state) => state.wellbeing);
  const { masterId, isEditMode } = benguide;
  const defaultContent = '';
  const { id, benefitKind, benefitCarrier = {} as BenefitCarrier } = plan;
  const { benefitDescription = '' } = benefitCarrier;
  const richMediaForPlan = get(richMedia, id);
  const { content, inProgress: isRichMediaLoading } = richMediaForPlan || {};

  const isContentReceived = content !== undefined;
  const [
    isSavePlanDescriptionModalVisible,
    setIsSavePlanDescriptionModalVisible,
  ] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState(false);

  const [editorContent, setEditorContent] = useState<string>(defaultContent);
  const planCardEditorRef = useRef<any>();
  const loadContent = useCallback(() => {
    dispatch(getRichMediaContent(masterId, id));
  }, [dispatch, masterId, id]);

  const showAddContentBtn = useMemo(() => {
    return isEmpty(content) && isEditMode;
  }, [content, isEditMode]);

  const saveContent = (content: string) => {
    dispatch(
      updateRichMediaContent(masterId, id, content, () => {
        dispatch(
          reloadWellbeingSection(benguide.masterId, benguide.latestRevision)
        );
        dispatch(
          fetchWellbeingPlanList(
            benguide.employerId,
            benguide.planYearId,
            benguide.benefitClasses
          )
        );
        setIsSavePlanDescriptionModalVisible(false);
      })
    );
  };

  useEffect(() => {
    loadContent();
  }, [loadContent, plan?.description]);

  useEffect(() => {
    setEditorContent(content || defaultContent);
  }, [content]);

  const handleResetDefault = () => {
    setEditorContent(`<p>${benefitDescription}</p>`);
    planCardEditorRef.current.changeContent(`<p>${benefitDescription}</p>`);
  };

  const handleDescriptionEditSubmit = (content: string) => {
    const isDescriptionEdited = plan && plan.description !== content;
    // We dont saveContent() if description is not edited as it is useless API call
    if (isDescriptionEdited) {
      setIsSavePlanDescriptionModalVisible(true);
    }
  };

  const renderHoverMoveOverlay = () => (
    <div className={styles.moveOverlay}>
      {/* Top Section */}
      {!hideTop ? (
        <div className={styles.top} onClick={moveTop}>
          <LeftArrowIcon className={styles.topIcon} />
          <label>Move</label>
        </div>
      ) : (
        <div className={styles.emptyDiv}></div>
      )}

      {/* Bottom Section */}
      {!hideBottom ? (
        <div className={styles.bottom} onClick={moveBottom}>
          <label>Move</label>
          <RightArrowIcon className={styles.bottomIcon} />
        </div>
      ) : (
        <div className={styles.emptyDiv}></div>
      )}
    </div>
  );

  const setCurrentConetnt = () => {
    setEditorContent(content);
  };

  return (
    <div className={styles.wellbeingCardWrapper}>
      <div className={styles.moveWrapper}>
        <EditableHotspot
          alwaysVisible={false}
          className={styles.hotSpotHoverContainer}
          customModal={() => <></>}
          overlayComponent={renderHoverMoveOverlay()}
        >
          <div className={styles.moveArea}></div>
        </EditableHotspot>
      </div>
      <PlanCardContainer>
        <>
          {isTablet && <div className={styles.topShadow}></div>}
          <div className={styles.summaryWrapper}>
            <EditableHotspot
              alwaysVisible={false}
              editText="Edit"
              editIcon={<img src={iconEdit} alt="edit" />}
              useChannel={{
                client: upClient,
                channel: WELLBEING_PLAN_CHANNEL,
                event: OPEN_WELLBEING_PLAN_EDIT_MODAL,
                data: plan,
              }}
            >
              <div className={styles.summary}>
                <div className={styles.planHeaderWrapper}>
                  <div className={styles.logoWrapper}>
                    {plan.benefitCarrier && (
                      <img
                        src={plan.benefitCarrier.logoUrl}
                        alt={`${plan.benefitCarrier.name} logo`}
                        className={styles.logo}
                      />
                    )}
                  </div>
                  <TooltipPopover content={plan.name} placement="right">
                    <div className={styles.planName}>{plan.name}</div>
                  </TooltipPopover>
                </div>
                {(!isEmpty(plan.documents) ||
                  !isEmpty(plan.additionalDocuments) ||
                  !isEmpty(plan.additionalWeblinks)) && (
                  <div
                    className={styles.viewDetailsLink}
                    onClick={() => setIsVisible(true)}
                  >
                    Plan Resources
                  </div>
                )}
              </div>
            </EditableHotspot>
          </div>
        </>
      </PlanCardContainer>

      <div
        className={
          showAddContentBtn ? styles.addTextWrapper : styles.description
        }
      >
        {!isContentReceived ? (
          <Spinner />
        ) : (
          <EditableHotspot
            className={showAddContentBtn ? styles.addTextHotspot : ''}
            alwaysVisible={showAddContentBtn}
            editText={showAddContentBtn ? '' : 'Edit'}
            editIcon={
              showAddContentBtn ? <></> : <img src={iconEdit} alt="edit" />
            }
            customModal={(
              visible: boolean,
              onClose: () => {},
              editorKey: string
            ) => (
              <PlanCardEditor
                isOpenModal={visible}
                closeModal={onClose}
                editorContent={editorContent}
                isResetEnabled={true}
                resetToCurrentValue={() => {}}
                resetToDefault={handleResetDefault}
                defaultContent={`<p>${benefitDescription}</p>`}
                updateEditorContent={setEditorContent}
                benguideId={masterId}
                onSubmit={handleDescriptionEditSubmit}
                ref={planCardEditorRef}
                setCurrentConetnt={setCurrentConetnt}
                editorKey={editorKey}
              />
            )}
          >
            {showAddContentBtn ? (
              <AddYourText />
            ) : (
              <div className={styles.content}>
                <ContentPreviewer
                  htmlContent={
                    isEmpty(editorContent) ? defaultContent : editorContent
                  }
                />
              </div>
            )}
          </EditableHotspot>
        )}
      </div>
      <SavePlanDescriptionModal
        confirmLoading={isRichMediaLoading}
        isVisible={isSavePlanDescriptionModalVisible}
        onClose={() => {
          setIsSavePlanDescriptionModalVisible(false);
          setEditorContent(plan?.description || '');
        }}
        onConfirm={() => saveContent(editorContent)}
      />
      <ViewResourcesModal
        plan={plan}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        benefitKind={benefitKind}
      />
    </div>
  );
};
export default WellbeingCard;
